import { map } from "rxjs";
import { DBFactory } from "~/classes/database/db_factory";
import { Announcement } from "./announcement.model";
import {
  UserAnnouncementLog,
  UserAnnouncementLogs,
} from "./user-announcement-logs.model";

export class AnnouncementsService {
  static strealAllAnnouncements() {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: "/announcements",
          collection: "/announcements",
        },
        [
          {
            type: "orderBy",
            field: "displayOrder",
            direction: "desc",
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((doc) => {
            return Announcement.fromMap(doc);
          });
        })
      );
  }

  static streamAllPublishedAnnouncements() {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: "/announcements",
          collection: "/announcements",
        },
        [
          {
            type: "where",
            field: "state",
            operator: "==",
            value: "published",
          },
          {
            type: "orderBy",
            field: "displayOrder",
            direction: "desc",
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((doc) => {
            return Announcement.fromMap(doc);
          });
        })
      );
  }

  static async fetchAnnouncementById({
    id,
  }: {
    id: string;
  }): Promise<Announcement> {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `/announcements/${id}`,
      collection: "/announcements",
    });

    return Announcement.fromMap(data);
  }

  static streamUserAnnouncementLogs({ userId }: { userId: string }) {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: `/userAnnouncementLogs`,
          collection: `/userAnnouncementLogs`,
        },
        [
          {
            type: "where",
            field: "userId",
            operator: "==",
            value: userId,
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((doc) => {
            return UserAnnouncementLog.fromMap(doc);
          });
        })
      );
  }

  static async saveUserLogs({ userLogs }: { userLogs: UserAnnouncementLogs }) {
    const db = DBFactory.createDatabase();
    await db.batchUpdate(userLogs);
  }
}
