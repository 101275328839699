import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "~/classes/models/base.model";

// Announcemnet Log
// - userId: string
// - releaseNoteId: string
// - createdAtTimestamp: number

export type UserAnnouncementLogs = UserAnnouncementLog[];

type UserAnnouncementLogConstructorParams = {
  userId: string;
  announcementId: string;
  createdAtTimestamp: number;
};

export class UserAnnouncementLog extends BaseModel {
  id: string;
  userId: string;
  announcementId: string;
  createdAtTimestamp: number;

  constructor(params: UserAnnouncementLogConstructorParams) {
    super();
    this.userId = params.userId;
    this.announcementId = params.announcementId;
    this.createdAtTimestamp = params.createdAtTimestamp;
    this.id = `${this.userId}_${this.announcementId}`;
  }

  static fromMap(data: any): UserAnnouncementLog {
    return new UserAnnouncementLog(data);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/userAnnouncementLogs`,
      path: `/userAnnouncementLogs/${this.userId}_${this.announcementId}`,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();

    await db.save(this.toMap(), this.databaseConfig);
  }
}
